<template>
  <v-container style="min-height: 85vh" fluid>
    <FormsContainer />
  </v-container>
</template>

<script>
import FormsContainer from "@/views/forms_container/FormsContainer";

export default {
  name: "Container",
  components: {
    FormsContainer
  }
};
</script>
