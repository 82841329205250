<template>
  <div>
    <v-card class="mb-12 pb-0" color="grey lighten-5">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="$v.form.customer_name.$model"
              label="Nome Completo*"
              color="red lighten-1"
              required
              :error-messages="errors.customer_name"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="$v.form.cpf.$model"
              label="CPF*"
              v-mask="'###.###.###-##'"
              color="red lighten-1"
              required
              :error-messages="errors.cpf"
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="$v.form.phone_number.$model"
              label="Numero do Celular*"
              v-mask="'(##) #########'"
              color="red lighten-1"
              required
              :error-messages="errors.phone_number"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="$v.form.email.$model"
              label="Endereço de Email*"
              color="red lighten-1"
              required
              :error-messages="errors.email"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-btn
      color="rgb(199, 56, 58)"
      @click="openStep(2)"
      style="color: white !important"
    >
      Avançar
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { required, email, minLength } from "vuelidate/lib/validators";
import { checkFormErrors } from "@/global/errorsMessages";
import { mask } from "vue-the-mask";

export default {
  name: "DonorDetails",
  directives: { mask },
  data: () => ({
    form: {
      customer_name: "",
      cpf: "",
      phone_number: "",
      email: ""
    },
    errors: {
      customer_name: [],
      cpf: [],
      phone_number: [],
      email: []
    }
  }),
  validations: {
    form: {
      customer_name: { required },
      cpf: { required, minLength: minLength(14) },
      phone_number: { required, minLength: minLength(14) },
      email: { required, email }
    }
  },
  methods: {
    ...mapActions("donor", ["ActionSetDonorDetails"]),

    openStep(step) {
      this.$v.$touch();
      this.errors = checkFormErrors(this.$v.form);

      if (!this.$v.$invalid) {
        this.ActionSetDonorDetails(this.form);

        this.$emit("emitDonorDetails", step);
      } else {
        console.log("invalid");
      }
    }
  }
};
</script>
