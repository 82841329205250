import donor from "@/modules/donor";
import donation from "@/modules/donation";
import donation_form from "@/modules/donation_form";
import payment from "@/modules/payment";

export default {
  donor,
  donation,
  payment,
  donation_form
};
