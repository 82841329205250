import Vue from "vue";
import VueResource from "vue-resource";
import RESOURCES from "@/global/resources";

Vue.use(VueResource);

const http = Vue.http;
http.options.root = process.env.VUE_APP_BASE_URL + RESOURCES.API_URI;
http.options.emulateJSON = true;

export { http };
