import * as api from "@/http";

export const ActionStoreDonation = async ({ commit }, form) => {
  let api_route = "";

  switch (form.payment_type) {
    case "debit_card":
      api_route = "sale/debit";
      break;
    case "credit_card":
      if (form.confirm_recurrencie) {
        api_route = "sale/recurrent";
      } else {
        api_route = "sale/credit";
      }
      break;
  }

  const donation_details = await api.http
    .post(api_route, form)
    .then(response => response.body);

  commit("SET_donation_form", donation_details);
  return donation_details;
};
