<template>
  <v-app :style="bg_payment" class="bg_payment">
    <v-main :style="sticker_turminha" class="sticker_turminha">
      <Container />
    </v-main>
  </v-app>
</template>

<script>
import Container from "./components/dashboard/Container";
import BackgroundImage from "./assets/bg_donation.png";
import StickerTurminha from "./assets/sticker_turminha.png";

export default {
  name: "App",

  components: {
    Container,
  },

  data: () => ({
    bg_payment: { backgroundImage: "url(" + BackgroundImage + ")" },
    sticker_turminha: { backgroundImage: "url(" + StickerTurminha + ")" },
  }),
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Doe para o IPH!";
      },
    },
  },
};
</script>

<style>
.bg_payment {
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  background-position: center !important;
  background-size: 100% 100% !important;
}

.sticker_turminha {
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
    background-position: center 95%;
  }

@media screen and (min-width: 300px) and (max-width: 900px) {
  .sticker_turminha {
    background-size: 70% auto !important;
  }
}

@media screen and (min-width: 900px) {
  .sticker_turminha {
    background-size: 30% auto !important;
  }
}
</style>
