<template>
  <div>
    <v-card class="mb-12 pb-0" color="grey lighten-5">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="7" lg="7" xl="7">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="$v.form.donation_value.$model"
                  label="Valor da Doação*"
                  v-money="money"
                  color="red lighten-1"
                  required
                  :error-messages="errors.donation_value"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row
              v-if="form.payment_type == 'credit_card'"
              class="rounded-lg px-3 pb-2"
              :style="dashed_div"
            >
              <v-col cols="12" class="mb-0 pb-0">
                <v-checkbox
                  v-model="$v.form.confirm_recurrencie.$model"
                  label="Você deseja tornar está uma doação recorrente?"
                  color="red lighten-1"
                  :style="{ fontWeight: 'bold' }"
                  @click="
                    dialog = $v.form.confirm_recurrencie.$model ? true : false
                  "
                ></v-checkbox>
              </v-col>

              <v-col cols="12" class="mt-0 pt-0">
                <div :style="important_text">
                  <small>
                    Selecionando a opção doação recorrente, você está
                    concordando em doar de forma continua para o IPH.<br />
                    Todos os meses no mesmo dia desta doação será cobrado do seu
                    cartão de crédito um valor igual ao primeiro valor doado.
                  </small>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="1" lg="1" xl="1">
            <hr width="1" size="500" />
          </v-col>

          <v-col cols="12" md="4" lg="4" xl="4">
            <v-row>
              <v-col cols="12">
                <v-radio-group
                  v-model="$v.form.payment_type.$model"
                  mandatory
                  required
                  :error-messages="errors.payment_type"
                >
                  <v-radio
                    label="Cartão de Crédito"
                    color="rgb(199, 56, 58)"
                    value="credit_card"
                  ></v-radio>
                  <v-radio
                    label="Cartão de Débito"
                    color="rgb(199, 56, 58)"
                    value="debit_card"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-btn
      color="rgb(199, 56, 58)"
      @click="openStep(3)"
      style="color: white !important"
    >
      Avançar
    </v-btn>

    <v-btn text @click="openStep(1)"> Voltar </v-btn>

    <!-- CONFIRMATION MODAL -->
    <div class="text-center">
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title class="headline lighten-2">
            Sobre a doação recorrente:
          </v-card-title>

          <v-card-text :style="important_text" class="mt-3">
            Ao escolher a opção de doação recorrente você receberá todos os
            meses no mesmo dia desta primeira doação uma cobrança em seu cartão
            de credito no valor desta doação. <br />
            Caso deseje cancelar uma doação recorrente fale com um de nossos
            atendentes. Basta ligar no: (85) 30511369.<br />
            Se você não deseja doar todos os meses basta deixar a opção da
            doação recorrente desmarcada e seguir com sua doação normalmente.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="rgb(199, 56, 58)" text @click="dialog = false">
              Entendi!
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import { VMoney } from "v-money";
import { checkFormErrors } from "@/global/errorsMessages";

export default {
  name: "DonationDetails",
  directives: { money: VMoney },
  data: () => ({
    dialog: false,
    important_text: {
      textAlign: "justify",
      textJustify: "inter-word",
    },
    dashed_div: {
      borderStyle: "dashed",
      borderColor: "#d98f35",
      background: "#ffe176",
    },
    money: {
      decimal: ".",
      thousands: " ",
      prefix: "R$ ",
      precision: 2,
      masked: false,
    },
    form: {
      donation_value: "",
      confirm_recurrencie: false,
      payment_type: "credit_card",
    },
    errors: {
      donation_value: [],
      confirm_recurrencie: [],
      payment_type: [],
    },
  }),
  validations: {
    form: {
      donation_value: { required },
      confirm_recurrencie: {},
      payment_type: { required },
    },
  },
  methods: {
    ...mapActions("donation", ["ActionSetDonationDetails"]),

    openStep(step) {
      if (step >= 2) {
        this.$v.$touch();
        this.errors = checkFormErrors(this.$v.form);

        if (!this.$v.$invalid) {
          if (this.form.payment_type != "credit_card") {
            this.form.confirm_recurrencie = false;
          }
          this.ActionSetDonationDetails(this.form);

          this.$emit("emitDonationDetails", step);
        } else {
          console.log("invalid");
        }
      } else {
        this.$emit("emitDonationDetails", step);
      }
    },
  },
};
</script>
