<template>
  <v-container>
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" color="rgb(199, 56, 58)" step="1">
          Detalhes do Doador
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="step > 2" color="rgb(199, 56, 58)" step="2">
          Detalhes da Doação
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step color="rgb(199, 56, 58)" step="3">
          Detalhes do Pagamento
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <DonorDetails @emitDonorDetails="openStep" />
        </v-stepper-content>

        <v-stepper-content step="2">
          <DonationDetails @emitDonationDetails="openStep" />
        </v-stepper-content>

        <v-stepper-content step="3">
          <PaymentDetails @emitPaymentDetails="openStep" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-dialog v-model="confirm_storage_dialog" width="500">
      <v-card>
        <v-card-title class="headline" color="rgb(199, 56, 58)">
          Detalhes da Doação
        </v-card-title>

        <v-card-text v-if="GET_payment.installments_number == 1 && GET_donation.confirm_recurrencie">
          Periodicidade: Mensal
        </v-card-text>

        <v-card-text v-else>
          Numero de Parcelas: {{this.GET_payment.installments_number}}
        </v-card-text>

        <v-divider></v-divider>
        <v-card-text>
          <b>Total: </b> {{this.GET_donation.donation_value}}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-row>
            <v-col cols="6" sm="6" md="6" lg="6" xl="6">
              <v-btn color="rgb(199, 56, 58)" text @click="storeCourse()" :disabled="loading"> Concordo </v-btn>
            </v-col>
            <v-col cols="6" sm="6" md="6" lg="6" xl="6">
              <v-btn color="secondary" text @click="confirm_storage_dialog = false" :disabled="loading"> Cancelar </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mask } from "vue-the-mask";
import { VMoney } from "v-money";
import { mapGetters, mapActions } from "vuex";
import DonorDetails from "@/views/forms_container/components/DonorDetails";
import DonationDetails from "@/views/forms_container/components/DonationDetails";
import PaymentDetails from "@/views/forms_container/components/PaymentDetails";

export default {
  name: "FormsContainer",
  directives: { mask, money: VMoney },
  components: {
    DonorDetails,
    DonationDetails,
    PaymentDetails,
  },
  data: () => ({
    step: 1,
    confirm_storage_dialog: false,
    loading: false,
  }),
  computed: {
    ...mapGetters("donation", ["GET_donation"]),
    ...mapGetters("donor", ["GET_donor"]),
    ...mapGetters("payment", ["GET_payment"]),
  },
  methods: {
    ...mapActions("donation_form", ["ActionStoreDonation"]),

    openStep(step) {
      if (step != 4) {
        this.step = step;
      } else {
        this.confirmStorage();
      }
    },

    clearForms() {
      location.reload();
    },

    confirmStorage() {
      this.confirm_storage_dialog = true;
    },

    async storeCourse() {
      this.loading = true
      
      try {
        let form = {
          ...this.GET_donation,
          ...this.GET_donor,
          ...this.GET_payment,
        };

        form.donation_value = parseFloat(
          form.donation_value.replace("R$ ", "")
        );

        if (form.card_expiration_date.length < 7) {
          var splited_date = form.card_expiration_date.split("/");

          if (parseInt(splited_date[1]) < 2000) {
            form.card_expiration_date = form.card_expiration_date.replace(
              "/",
              "/20"
            );
          }
        }

        let request_return = await this.ActionStoreDonation(form);

        if (request_return.error) {
          if (request_return.cielo_error_code == 126) {
            this.$swal({
              icon: "error",
              title: "Erro",
              text: "A data de vencimento do cartão é invalida.",
              // willClose: this.clearForms,
            });
            this.loading = false
          } else if (request_return.cielo_error_code == 146) {
            this.$swal({
              icon: "error",
              title: "Erro",
              text: "O código de segurança do cartão inserido é invalido.",
              // willClose: this.clearForms,
            });
            this.loading = false
          } else if (request_return.cielo_error_code == 309) {
            this.$swal({
              icon: "error",
              title: "Erro",
              text: "Um erro foi encontrado nos dados do seu cartão, verifique os dados do seu cartão de crédito e tente novamente.",
              // willClose: this.clearForms,
            });
            this.loading = false
          } else {
            this.$swal({
              icon: "error",
              title: "Erro",
              text: request_return.serve_error_message,
              willClose: this.clearForms,
            });
            this.loading = false
          }
        } else {
          this.$swal({
            icon: "success",
            title: "Concluida",
            text: "A Doação Foi Concluida com Sucesso",
            willClose: this.clearForms,
          });
        }
      } catch (error) {
        if (error.body) {
          this.$swal({
            icon: "error",
            title: "Erro",
            text: error.body.message,
            // willClose: this.clearForms,
          });
          this.loading = false
        }
      }
    },
  },
};
</script>
