export const messages = {
  required: "Campo obrigatório.",
  email: "E-mail inválido.",
  minLength: number =>
    `O campo deve ter mais de ${number} caracter${number > 1 ? "es" : ""}.`,
  maxLength: number =>
    `O campo deve ter menos de ${number} caracter${number > 1 ? "es" : ""}.`,
  sameAs: `Os campos devem ser iguais.`
};

export const checkFormErrors = form => {
  form.$touch();
  const keys = Object.keys(form.$params);
  const errors = {};
  keys.map(key => {
    const error = [];
    const rules = Object.keys(form[key].$params);
    rules.map(rule => {
      const rule_values = Object.values(form[key].$params[rule]);
      if (!form[key][rule]) {
        if (rule_values.length > 1) {
          error.push(messages[rule](rule_values[1]));
        } else {
          error.push(messages[rule]);
        }
      }
    });
    errors[key] = error;
  });
  return errors;
};
