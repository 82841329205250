import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { http } from "@/http";
import "@/plugins/vuelidate";
import VueSweetAlert from "vue-sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetAlert);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  http,

  render: h => h(App)
}).$mount("#app");
