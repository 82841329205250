<template>
  <div>
    <v-card class="mb-12 pb-0" color="grey lighten-5">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="$v.form.card_owner_name.$model"
              label="Nome no Cartão*"
              color="red lighten-1"
              required
              :error-messages="errors.card_owner_name"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8" lg="6" xl="6">
            <v-text-field
              v-model="$v.form.card_number.$model"
              label="Numero do Cartão*"
              color="red lighten-1"
              v-mask="'#### #### #### #### ####'"
              required
              :error-messages="errors.card_number"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4" lg="2" xl="2">
            <v-select
              v-model="$v.form.card_flag_code.$model"
              :items="card_brands"
              item-text="text"
              item-value="value"
              label="Bandeira"
              hide-selected
              :error-messages="errors.card_flag_code"
            ></v-select>
          </v-col>

          <v-col cols="6" lg="2" xl="2">
            <v-text-field
              v-model="$v.form.card_cvv.$model"
              label="CVV*"
              color="red lighten-1"
              v-mask="'####'"
              required
              :error-messages="errors.card_cvv"
            ></v-text-field>
          </v-col>

          <v-col cols="6" lg="2" xl="2">
            <v-text-field
              v-model="$v.form.card_expiration_date.$model"
              label="Data de Vencimento do Cartão*"
              color="red lighten-1"
              v-mask="'##/####'"
              required
              :error-messages="errors.card_expiration_date"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row
          v-if="
            GET_donation.payment_type == 'credit_card' &&
            GET_donation.confirm_recurrencie == false
          "
        >
          <v-col cols="12">
            <v-select
              v-model="$v.form.installments_number.$model"
              :items="installment_options"
              item-text="text"
              item-value="value"
              label="Opções de Parcelamento"
              outlined
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-btn
      color="rgb(199, 56, 58)"
      @click="openStep(4)"
      style="color: white !important"
    >
      Concluir
    </v-btn>

    <v-btn text @click="openStep(2)"> Voltar </v-btn>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { mask } from "vue-the-mask";
import { mapActions, mapGetters, mapState } from "vuex";
import { checkFormErrors } from "@/global/errorsMessages";

export default {
  name: "PaymentDetails",
  directives: { mask },
  data: () => ({
    installment_options: [],
    card_brands: [
      { text: "Visa", value: 1 },
      { text: "Mastercard", value: 2 },
      { text: "American Express", value: 3 },
      { text: "Elo", value: 4 },
      { text: "Aura", value: 5 },
      { text: "Jcb", value: 6 },
      { text: "Diners", value: 7 },
      { text: "Discover", value: 8 },
      { text: "Hipercard", value: 9 },
    ],
    form: {
      card_owner_name: "",
      card_number: "",
      card_cvv: "",
      card_expiration_date: "",
      installments_number: "",
      card_flag_code: "",
    },
    errors: {
      card_owner_name: [],
      card_number: [],
      card_cvv: [],
      card_expiration_date: [],
      installments_number: [],
      card_flag_code: [],
    },
  }),
  validations: {
    form: {
      card_owner_name: { required },
      card_number: { required, minLength: minLength(17) },
      card_cvv: { required, minLength: minLength(3) },
      card_expiration_date: { required },
      installments_number: {},
      card_flag_code: { required },
    },
  },
  watch: {
    donation: {
      deep: true,
      handler() {
        this.setInstalmments();
      },
    },
  },
  computed: {
    ...mapGetters("donation", ["GET_donation"]),
    ...mapState("donation", ["donation"]),
  },
  methods: {
    ...mapActions("payment", ["ActionSetPaymentDetails"]),

    setInstalmments() {
      if (Object.entries(this.donation).length != 0) {
        let donated_amout = parseFloat(
          this.GET_donation.donation_value.replace("R$ ", "")
        );
        this.form.installments_number = 1;
        if (
          this.GET_donation.payment_type == "credit_card" &&
          this.GET_donation.confirm_recurrencie == false
        ) {
          for (let index = 1; index <= 12; index++) {
            let fractioned_amounted = (donated_amout / index).toFixed(2);
            if (index == 1) {
              this.installment_options.push({
                text: "R$ " + fractioned_amounted + " a vista.",
                value: index,
              });
            } else {
              this.installment_options.push({
                text: index + "x parcelas de R$ " + fractioned_amounted + ".",
                value: index,
              });
            }
          }
        }
      }
    },

    openStep(step) {
      if (step >= 3) {
        this.$v.$touch();
        this.errors = checkFormErrors(this.$v.form);

        if (!this.$v.$invalid) {
          this.ActionSetPaymentDetails(this.form);

          this.$emit("emitPaymentDetails", step);
        } else {
          console.log("invalid");
        }
      } else {
        this.$emit("emitPaymentDetails", step);
      }
    },
  },
};
</script>
